<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irrigation_config.report_heading') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="2"
            :label="$t('irrigation_config.addressl')"
            label-for="address"
          >
            <b-form-input
              plain
              v-model="search.address"
              id="address"
            >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.report_heading') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(left_logo)="data">
                      <b-img width='100px' :src="virtualMuseumServiceBaseUrl + 'storage/report-heading/left-logo/' + data.item.left_logo" fluid alt="Left Logo"></b-img>
                    </template>
                    <template v-slot:cell(right_logo)="data">
                      <b-img width='100px' :src="virtualMuseumServiceBaseUrl + 'storage/report-heading/right-logo/' + data.item.right_logo" fluid alt="Right Logo"></b-img>
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ (data.item.status == 1) ? $t('globalTrans.active') : $t('globalTrans.inactive') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                      <!-- <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" :title="$t('globalTrans.edit')" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" :title="$t('globalTrans.active')" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" :title="$t('globalTrans.inactive')" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './FormV'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, reportToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        org_id: 0,
        address: ''
      },
      virtualMuseumServiceBaseUrl: virtualMuseumServiceBaseUrl
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.editItemId === 0) ? this.$t('irrigation_config.report_heading_entry') : this.$t('irrigation_config.report_heading') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('irrigation_config.organization'), class: 'text-center' },
        { label: this.$t('irrigation_config.addressl'), class: 'text-center' },
        { label: this.$t('irrigation_config.left_logo'), class: 'text-center' },
        { label: this.$t('irrigation_config.right_logo'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'address_bn' },
          { key: 'left_logo' },
          { key: 'right_logo' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'address' },
          { key: 'left_logo' },
          { key: 'right_logo' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    if (this.authUser.role_id === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: 0
      })
    } else {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(virtualMuseumServiceBaseUrl, reportToggleStatus, item)
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(virtualMuseumServiceBaseUrl, reportHeadingList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    dataList (data) {
      const orgList = this.$store.state.orgList
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
        const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    }
  }
}
</script>
